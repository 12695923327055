import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { Form, Button, List, Loader, Message, Segment, Header, Icon } from "semantic-ui-react";
import { useStore } from "../../app/stores/store";
import { NotificationEmail, NotificationEmailFormValues } from "../../app/models/emailNotification";
import { useTranslation } from "react-i18next";
import ConfirmComponent from "../../app/common/form/ConfirmComponent";

export default observer(function NotificationEmailScreen() {
  const { notificationEmailStore, modalStore } = useStore();
  const { emails, addEmail, removeEmail, fetchEmails, loading } = notificationEmailStore;
  const [formData, setFormData] = useState<NotificationEmailFormValues>({
    email: "",
  });
  const { t } = useTranslation();

  useEffect(() => {
    fetchEmails();
  }, [fetchEmails]);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (formData.email) {
      await addEmail(formData);
      await fetchEmails();
      setFormData({ email: "" });
    }
  };

  const handleRemove = async (id: string) => {
    await removeEmail(id);
    await fetchEmails();
  };

  return (
    <div>
      <Header as="h2" dividing>
        {t('notificationEmails.title')}
      </Header>

      {loading ? (
        <Loader active inline="centered" />
      ) : (
        <>
          <Segment>
            <Form onSubmit={handleSubmit}>
              <Form.Input
                value={formData.email}
                onChange={(e) => setFormData({ email: e.target.value })}
                placeholder={t('notificationEmails.enterEmail')}
                fluid
                required
              />
              <Button
                type="submit"
                primary
                fluid
                disabled={!formData.email}
              >
                {t('notificationEmails.addEmail')}
              </Button>
            </Form>
          </Segment>

          {emails.length > 0 ? (
            <Segment>
              <List divided relaxed>
                {emails?.map((item: NotificationEmail, index: number) => (
                  <List.Item key={index}>
                    <List.Content floated="right">
                      <Button
                        icon
                        color="red"
                        onClick={(event) => {
                          event.stopPropagation();
                          modalStore.openModal(
                            <ConfirmComponent
                              onYes={async () => {
                                handleRemove(item.id!);
                                modalStore.closeModal();
                              }}
                              onCancel={modalStore.closeModal}
                            />
                          );
                        }}
                        size="tiny"
                        compact
                      >
                        <Icon name="trash" />
                      </Button>
                    </List.Content>
                    <List.Content>
                      <List.Header>{item.email}</List.Header>
                    </List.Content>
                  </List.Item>
                ))}
              </List>
            </Segment>
          ) : (
            <Message info>{t('notificationEmails.noEmails')}</Message>
          )}
        </>
      )}
    </div>
  );
});
