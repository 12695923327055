import { Container, Dropdown, Menu, Image } from "semantic-ui-react";
import { Link, NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react-lite";
import { useState } from "react";
import { UserRole } from "../common/enums/userRole";
import { useStore } from "../stores/store";
import LanguageSelector from "./LanguageSelector";

export default observer(function NavBar() {
  const {
    userStore: { loggedUser, logout, userRole },
  } = useStore();
  const { t, i18n } = useTranslation();

  const [selectedLanguage, setSelectedLanguage] = useState(i18n.language);
  const changeLanguage = (language: string) => {
    i18n.changeLanguage(language);
    setSelectedLanguage(language);
  };

  return (
    <Menu inverted fixed="top">
      <Container style={{ marginTop: "0.5em" }}>
        {userRole === UserRole.ADMIN && (
          <Menu.Item as={NavLink} to="/" header>
            <img
              src="/assets/logo.png"
              alt="logo"
              style={{ marginRight: 10 }}
            />
            {t("general.organizationName")}
          </Menu.Item>
        )}

        {userRole === UserRole.ADMIN && (
          <Menu.Item as={NavLink} to="/home">
            {t("navbar.home")}
          </Menu.Item>
        )}

        {userRole === UserRole.ADMIN && (
          <Dropdown item text={t("navbar.members")}>
            <Dropdown.Menu>
              <Dropdown.Item
                as={NavLink}
                to="/members"
                text={t("navbar.allMembers")}
              />
              <Dropdown.Item
                as={NavLink}
                to="/pending-members"
                text={t("navbar.pendingMembers")}
              />
              <Dropdown.Item
                as={NavLink}
                to="/create-member"
                text={t("navbar.addMember")}
              />
              <Dropdown.Item
                as={NavLink}
                to="/upload-members"
                text={t("navbar.uploadMembers")}
              />
            </Dropdown.Menu>
          </Dropdown>
        )}

        {userRole === UserRole.ADMIN && (
          <Dropdown item text={t("navbar.payments")}>
            <Dropdown.Menu>
              <Dropdown.Item
                as={NavLink}
                to="/unlinked-payments"
                text={t("navbar.unlinkedPayments")}
              />
              <Dropdown.Item
                as={NavLink}
                to="/upload-payments"
                text={t("navbar.uploadPayments")}
              />
            </Dropdown.Menu>
          </Dropdown>
        )}

        {userRole === UserRole.SUPER_ADMIN && (
          <Dropdown item text={t("navbar.administration")}>
            <Dropdown.Menu>
              <Dropdown.Item
                as={NavLink}
                to="/users-create"
                text={t("navbar.addAdministrator")}
              />
              <Dropdown.Item
                as={NavLink}
                to="/tenants-create"
                text={t("navbar.addOrganization")}
              />
            </Dropdown.Menu>
          </Dropdown>
        )}

        {userRole === UserRole.ADMIN && (
          <Dropdown item text={t("navbar.activities")}>
            <Dropdown.Menu>
              <Dropdown.Item
                as={NavLink}
                to="/activities"
                text={t("navbar.activities")}
              />
              <Dropdown.Item
                as={NavLink}
                to="/create-activity"
                text={t("navbar.addActivity")}
              />
            </Dropdown.Menu>
          </Dropdown>
        )}

        {userRole === UserRole.ADMIN && (
          <Dropdown item text={t("navbar.reports")}>
            <Dropdown.Menu>
              <Dropdown.Item
                as={NavLink}
                to="/unpaid-memberships"
                text={t("navbar.unpaidMemberships")}
              />
              <Dropdown.Item
                as={NavLink}
                to="/paid-memberships"
                text={t("navbar.paidMemberships")}
              />
            </Dropdown.Menu>
          </Dropdown>
        )}

        {userRole === UserRole.ADMIN && (
          <Dropdown item text={t("navbar.settings")}>
            <Dropdown.Menu>
              <Dropdown.Item
                as={NavLink}
                to="/new-member-notifications"
                text={t("navbar.emailNotifications")}
              />
            </Dropdown.Menu>
          </Dropdown>
        )}

        <Menu.Item position="right">
          {loggedUser && (
            <>
              <Image avatar spaced="right" src={"/assets/user.png"} />
              <Dropdown pointing="top left" text={loggedUser?.username}>
                <Dropdown.Menu>
                  <Dropdown.Item
                    as={Link}
                    to={`/my-profile`}
                    text={t("navbar.myProfile")}
                    icon="user"
                  />
                  <Dropdown.Item
                    onClick={logout}
                    text={t("navbar.logout")}
                    icon="power"
                  />
                </Dropdown.Menu>
              </Dropdown>
            </>
          )}
          <LanguageSelector />
        </Menu.Item>
      </Container>
    </Menu>
  );
});
