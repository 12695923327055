import { makeAutoObservable, runInAction } from "mobx";
import agent from "../api/agent";
import { NotificationEmail, NotificationEmailFormValues } from "../models/emailNotification";

export default class NotificationEmailStore {
    emails: NotificationEmail[] = [];
    newEmail = "";
    loading = false;

    constructor() {
        makeAutoObservable(this);
    }

    setNewEmail(email: string) {
        this.newEmail = email;
    }

    fetchEmails = async () => {
        this.loading = true;
        try {
            const emails = await agent.NotificationEmails.list();
            this.setEmails(emails);
        } catch (error) {
            console.error("Failed to fetch emails:", error);
        } finally {
            runInAction(() => {
                this.loading = false;
            });
        }
    };

    private setEmails = (emails: NotificationEmail[]) => {
        this.emails = emails;
    };

    addEmail = async (emailNotification: NotificationEmailFormValues) => {
        try {
            await agent.NotificationEmails.add(emailNotification);
        } catch (error) {
            console.log(error);
        }
    };

    removeEmail = async (id: string) => {
        try {
            await agent.NotificationEmails.remove(id);
        } catch (error) {
            console.log("Error removing email:", error);
        }
    };
}

export const notificationEmailStore = new NotificationEmailStore();
