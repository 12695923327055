import React from "react";
import { useTranslation } from "react-i18next"; // Import the useTranslation hook
import { useStore } from "../../app/stores/store";
import { Button, Form, Header, Segment } from "semantic-ui-react";
import { Formik } from "formik";
import * as Yup from "yup";
import CustomTextInput from "../../app/common/form/CustomTextInput";
import { toast } from "react-toastify";

export default function MyProfile() {
  const { t } = useTranslation();
  const { userStore } = useStore();
  const initialValues: ChangePasswordFormValues = {
    currentPassword: "",
    newPassword: "",
    confirmNewPassword: "",
  };

  const validationSchema = Yup.object({
    currentPassword: Yup.string().required(
      t("myProfile.currentPasswordRequired")
    ),
    newPassword: Yup.string()
      .required(t("myProfile.newPasswordRequired"))
      .min(8, t("myProfile.newPasswordMinLength"))
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^a-zA-Z0-9]).{8,}$/,
        t("myProfile.newPasswordComplexity")
      ),
    confirmNewPassword: Yup.string()
      .required(t("myProfile.confirmNewPasswordRequired"))
      .oneOf([Yup.ref("newPassword")], t("myProfile.passwordsDoNotMatch")),
  });

  const handlePasswordChange = async (
    values: ChangePasswordFormValues,
    resetForm: any
  ) => {
    try {
      await userStore.changePassword({
        currentPassword: values.currentPassword,
        newPassword: values.confirmNewPassword,
      });

      resetForm();
      toast.success(t("myProfile.passwordChangedSuccess"));
    } catch (error) {
      toast.error(t("myProfile.invalidCurrentPassword"));
      console.log(error);
    }
  };

  return (
    <>
      <Header
        as="h2"
        content={`${t("myProfile.username")} - ${userStore.loggedUser?.username
          }`}
      />
      <Segment clearing>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={(values, { resetForm }: any) =>
            handlePasswordChange(values, resetForm)
          }
          enableReinitialize={true}
        >
          {({ handleSubmit, isValid, isSubmitting, dirty, resetForm }) => (
            <Form
              className="ui form"
              onSubmit={handleSubmit}
              autoComplete="off"
            >
              <h2>{t("myProfile.changePassword")}</h2>
              <CustomTextInput
                type="password"
                name="currentPassword"
                placeholder={t("myProfile.currentPassword")}
                label={t("myProfile.currentPassword")}
              />
              <CustomTextInput
                type="password"
                name="newPassword"
                placeholder={t("myProfile.newPassword")}
                label={t("myProfile.newPassword")}
              />
              <CustomTextInput
                type="password"
                name="confirmNewPassword"
                placeholder={t("myProfile.confirmNewPassword")}
                label={t("myProfile.confirmNewPassword")}
              />
              <Button
                disabled={isSubmitting || !dirty || !isValid}
                loading={isSubmitting}
                floated="right"
                positive
                type="submit"
                content={t("myProfile.changePassword")}
              />
            </Form>
          )}
        </Formik>
      </Segment>
    </>
  );
}

interface ChangePasswordFormValues {
  currentPassword: string;
  newPassword: string;
  confirmNewPassword: string;
}
